import { Loader } from "@fanduel/og-fe-library";
import styles from "./loadingBackdrop.module.scss";

type LoadingBackdropProps = {
  loading: boolean;
};

const LoadingBackdrop = ({ loading }: LoadingBackdropProps) => {
  if (loading) {
    return (
      <div className={styles.loadingContainer} data-testid="loading-backdrop">
        <Loader size="medium" />
      </div>
    );
  }

  return null;
};

export default LoadingBackdrop;
